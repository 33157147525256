/*-------------- cookieLayer.js --------------- */
document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO cookielayer.js loaded");

    var cookieName = "cookie-policy";
    var cookieAcceptedClass = "is-closed";

    //Finde das div-element
    var currentObj = document.getElementById('js-cookie-notification');

    if (currentObj) {
        //Lese das Cookie mit dem ermittelten Namen aus
        var cookieObj = getCookie(cookieName);

        if (cookieObj != "") {
            currentObj.classList.add(cookieAcceptedClass);
            //update position from bronson bugfix for IE
            if (bronson.Notification !== undefined) {
                bronson.Notification.updatePosition(true);
            }
        }

        var closeButtons = currentObj.querySelectorAll(".js-notification-close");
        for (var i = 0; i < closeButtons.length; i++) {
            closeButtons[i].addEventListener('click', function () {
                setCookie(cookieName, "accepted", (365 * 24 * 3600));
            });
        }
    }

    /*-- Von https://stackoverflow.com/questions/10730362/get-cookie-by-name --*/
    function getCookie(cname) {
        var name = cname + "=",
            ca = document.cookie.split(';'),
            i,
            c,
            ca_length = ca.length;
        for (i = 0; i < ca_length; i += 1) {
            c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) !== -1) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function setCookie(variable, value, expires_seconds) {
        var d = new Date();
        d = new Date(d.getTime() + 1000 * expires_seconds);
        document.cookie = variable + '=' + value + '; path=/; expires=' + d.toGMTString() + '; Secure; SameSite=Strict';
    }

}, false);
/* --------------------- cookieLayer.js --------------- */
/* https://developer.mozilla.org/en-US/docs/Web/API/document/cookie
   https://stackoverflow.com/questions/10730362/get-cookie-by-name */
