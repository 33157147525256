console.debug("INFO VWFSUtils.js loaded");
window.VWFSUtils = {

    getTTL: function () {
        //This TTL is in seconds and defined as the following: Hours * minutes * seconds * milliseconds
        return 1 * 60 * 60;
    },
    /**
     * Get all URL parameters of the current browser URL.
     *
     * @returns Object of URL parameters as keys and their values (values is an array, if multiple values for a parameter exist)
     */
    getURLParameters: function () {
        let query = window.location.search;
        const result = {};
        if (query.charAt(0) === "?") {
            query = query.substring(1);
        }
        const vars = query.split("&");
        for (const varskey in vars) {
            const pair = vars[varskey].indexOf("=") >= 0 ? vars[varskey].split("=") : [vars[varskey], ""];
            if (typeof result[pair[0]] === "undefined") {
                // first entry with this name
                result[pair[0]] = decodeURIComponent(pair[1]);
            } else if (typeof result[pair[0]] === "string") {
                // second entry with this name
                result[pair[0]] = [result[pair[0]], decodeURIComponent(pair[1])];
            } else {
                // third or later entry with this name
                result[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }
        return result;
    },

    /**
     * Get a single URL parameter of the current browser URL by name.
     *
     * @returns URL parameter value (value is an array, if multiple values for the parameter exist)
     */
    getURLParameter: function (name) {
        return this.getURLParameters()[name];
    },

    /**
     * it dispatches events that compatible with all Browsers even IE
     */
    dispatchEvent: function (element, eventName, options) {
        let event;
        if (window.CustomEvent) {
            event = new CustomEvent(eventName, options);
        } else {
            event = document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, true, true, options);
        }
        element.dispatchEvent(event);
    },

    /**
     * IS INSIDE OF
     * Tests if the specified DOM element is located inside of another element.
     */
    isInsideOf: function (element, parentElementSelector) {
        const parentElement = document.querySelector(parentElementSelector);

        if (!parentElement) {
            return false;
        }

        let pElement = element.parentElement;
        while (pElement.nodeName != "HTML") {

            if (pElement == parentElement) {
                return true;
            } else {
                pElement = pElement.parentElement;
            }

        }

        return false;
    },

    /**
     * IS INSIDE OF
     * Tests if the specified DOM element is located inside of another element.
     */
    isInsideOfElement: function (element, parentElement) {
        if (!parentElement) {
            return false;
        }

        var pElement = element.parentElement;
        while (pElement.nodeName !== "HTML") {

            if (pElement === parentElement) {
                return true;
            } else {
                pElement = pElement.parentElement;
            }

        }

        return false;

    },

    /**
     * GET PARENT ELEMENT BY CLASS
     * Returns a parent element with a specific class or null
     */
    getParentElementByClass: function (element, parentElementClass) {

        if (!element || !parentElementClass) {
            return false;
        }

        var pElement = element.parentElement;
        while (pElement.nodeName != "HTML") {

            if (pElement.classList.contains(parentElementClass)) {
                return pElement;
            } else {
                pElement = pElement.parentElement;
            }

        }

        return null;

    },

    /**
     * SET IMAGE SOURCE-SET FROM JSON
     */
    setImageFromJSON: function (imageElement, vehicleImageUrl) {

        imageElement.srcset =
            vehicleImageUrl.S + ' 350w,' +
            vehicleImageUrl.M + ' 520w,' +
            vehicleImageUrl.L + ' 1280w,' +
            vehicleImageUrl.XL + ' 1600w,' +
            vehicleImageUrl.XXL + ' 3200w';

        imageElement.src = vehicleImageUrl.L;

    },

    /**
     * IS INTERNAL SOURCE
     * Tests an ImageUrlJson object wether  its URLs are pointing to an internal target.
     */
    isInternalSource: function (imageUrlsJson) {

        // REMOVE URL-PARAMETERS AND FRAGMENT / GET FILENAME
        var imageUrl = imageUrlsJson.L.split(/[#?]/)[0];
        imageUrl = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

        var urlParts = imageUrl.split(".");

        // DOES THE FILENAME HAS SELECTORS AND DOES THE SECTOR EQUALS 'l'
        return urlParts.length > 2 && urlParts[urlParts.length - 2] === "l";

    },

    /**
     * CREATE PICTURE ELEMENT
     * Creates a HTML Picture-Tag from given parameters.
     *
     * Sizes-Attribute is applied to the source-Elements. (providing no sizes causes, that the browser will select the imagesize depending on the browsers window inner-with.)
     * imgStyleClasses and Alt are applied to the Image-Tag.
     * ImageUrls has to be provided a Json. And imageStyles as String where the styles are seperated by spaces.
     * imgCssStyle optional css-styles
     * SvgPlaceholder optional as {width: x, height: x} if not 16:9 will be used
     */
    createPictureElement: function (imageUrlsJson, imgStyleClasses, alt, sizes, imgCssStyle, aspectRatio = {
        width: 16,
        height: 9
    }) {

        var pictureElement = document.createElement('picture');

        // ADD WEBP
        var dataSourceWebp = document.createElement('source');

        dataSourceWebp.setAttribute("srcset", VWFSUtils.getImageSourceSet(imageUrlsJson, "webp"));

        if (sizes) {
            dataSourceWebp.setAttribute("sizes", sizes);
        }
        dataSourceWebp.setAttribute("type", "image/webp");
        pictureElement.appendChild(dataSourceWebp);

        // ADD IMAGE
        var imageElement = document.createElement('img');
        imageElement.alt = alt;

        imageElement.setAttribute("src", "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20" + aspectRatio.width + "%20" + aspectRatio.height + "'%3e%3cpath%20fill='%23eee'%20d='M0%200H" + aspectRatio.width + "V" + aspectRatio.height + "H-" + aspectRatio.width + "'/%3e%3c/svg%3e");

        if (sizes) {
            imageElement.setAttribute("srcset", imageUrlsJson.L);
            imageElement.setAttribute("loading", "lazy")
        }

        imageElement.className = imgStyleClasses;
        if (imgCssStyle) {
            imageElement.style.cssText = imgCssStyle;
        }
        pictureElement.appendChild(imageElement);

        return pictureElement;

    },

    // CREATE IMAGE-ELEMENT
    createImageElement: function (imageUrl, imgStyleClasses, altTag, imgCssStyle, aspectRatio = {
        width: 16,
        height: 9
    }, lazyLoad = false) {

        const imageElement = document.createElement('img');
        imageElement.alt = altTag;
        if (lazyLoad) {
            imageElement.setAttribute("data-flickity-lazyload-src", "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20" + aspectRatio.width + "%20" + aspectRatio.height + "'%3e%3cpath%20fill='%23eee'%20d='M0%200H" + aspectRatio.width + "V" + aspectRatio.height + "H-" + aspectRatio.width + "'/%3e%3c/svg%3e");
            imageElement.setAttribute("data-flickity-lazyload-srcset", imageUrl);
        } else {
            imageElement.setAttribute("src", "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20" + aspectRatio.width + "%20" + aspectRatio.height + "'%3e%3cpath%20fill='%23eee'%20d='M0%200H" + aspectRatio.width + "V" + aspectRatio.height + "H-" + aspectRatio.width + "'/%3e%3c/svg%3e");
            imageElement.setAttribute("srcset", imageUrl);
        }
        imageElement.className = imgStyleClasses;
        if (imgCssStyle) {
            imageElement.style.cssText = imgCssStyle;
        }

        return imageElement;
    },

    createPictureElementFromVMS: function (imageUrlsJson, imgStyleClasses, alt, sizes, aspectRatio, lazyLoad = false) {
        const pictureElement = document.createElement('picture');

        // ADD IMAGE // DO NOT ADD OTHER FILE-FORMATS WITH SOURCE-TAG > LAZY LOADING WILL NOT WORK THEN
        const imageElement = document.createElement('img');

        let srcAttrName = "src";
        let srcsetAttrName = "srcset";
        if (lazyLoad) {
            srcAttrName = "data-flickity-lazyload-src";
            srcsetAttrName = "data-flickity-lazyload-srcset";
        }

        imageElement.type = "image/svg+xml";
        imageElement.setAttribute(srcAttrName, "data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20" + aspectRatio.width + "%20" + aspectRatio.height + "'%3e%3cpath%20fill='%23eee'%20d='M0%200H" + aspectRatio.width + "V" + aspectRatio.height + "H-" + aspectRatio.width + "'/%3e%3c/svg%3e");

        let images = imageUrlsJson && imageUrlsJson.images ? imageUrlsJson.images.imageFormats16_9 : undefined;
        if (aspectRatio.width === 3 && aspectRatio.height === 2) {
            images = imageUrlsJson && imageUrlsJson.images ? imageUrlsJson.images.imageFormats3_2 : undefined;
        }
        if (images && images.webp) {
            imageElement.type = "image/webp";
            imageElement.setAttribute(srcsetAttrName, VWFSUtils.getImageSourceSetAdvanced(images.webp));
        }

        imageElement.alt = alt;
        imageElement.sizes = sizes;
        imageElement.className = imgStyleClasses;
        pictureElement.appendChild(imageElement);

        return pictureElement;
    },

    getImageSourceSetAdvanced: function (imageUrlsJson) {
        return imageUrlsJson.S + ' 350w,' +
            imageUrlsJson.M + ' 520w,' +
            imageUrlsJson.L + ' 1280w,' +
            imageUrlsJson.XL + ' 1600w';
    },


    /**
     * Create Carousel Element
     */
    createCarouselElementForImages: function (vehicleImageUrls, activateFullscreenMode, sizes, alttag = "product-image", aspectRatio = {
        width: 16,
        height: 9
    }, fromVMS = false) {
        let carouselElement = document.createElement('div');
        carouselElement.classList.add("c-carousel");
        carouselElement.classList.add("js-carousel");
        carouselElement.classList.add("c-carousel--buttons-on-hover");
        carouselElement.setAttribute("data-slider-counter", "true");
        carouselElement.setAttribute("data-carousel-lazy-load", "2")
        if (activateFullscreenMode) {
            carouselElement.setAttribute("data-carousel-fullscreen", "true");
        }
        for (let imageUrl of vehicleImageUrls) {
            let carouselItem = document.createElement('div');
            carouselItem.classList.add("c-carousel__item");

            if (fromVMS) {
                let imageElement = VWFSUtils.createPictureElementFromVMS(
                    imageUrl,
                    "c-carousel__image",
                    alttag, sizes, aspectRatio, true);
                carouselItem.appendChild(imageElement)
            } else {
                let imageElement = VWFSUtils.createImageElement(
                    imageUrl,
                    "c-carousel__image",
                    alttag, null, aspectRatio, true);
                carouselItem.appendChild(imageElement)
            }

            carouselElement.appendChild(carouselItem);
        }
        return carouselElement;
    },

    /**
     * SET IMAGE SOURCE-SET
     */
    getImageSourceSet: function (imageUrlsJson, extension) {
        return VWFSUtils.replaceExtension(imageUrlsJson.S, extension) + ' 350w,' +
            VWFSUtils.replaceExtension(imageUrlsJson.M, extension) + ' 520w,' +
            VWFSUtils.replaceExtension(imageUrlsJson.L, extension) + ' 1280w,' +
            VWFSUtils.replaceExtension(imageUrlsJson.XL, extension) + ' 1600w,' +
            VWFSUtils.replaceExtension(imageUrlsJson.XXL, extension) + ' 3200w';
    },

    /**
     * REPLACE FILE EXTENSION
     * Replaces the file extension from an URL.
     *
     * VWFSUtils.replaceExtension("http://my.domain/path/file.xml?param=1", "webp");
     *
     * => http://my.domain/path/file.webp?param=1
     */
    replaceExtension: function (path, extension) {

        var urlParts = path.split("?");

        // REPLACE EXTENSION
        var newPath = urlParts[0].substring(0, path.lastIndexOf(".")) + "." + extension;

        // ADD URL-PARAETER
        if (urlParts[1] != null) {
            newPath += "?" + urlParts[1];
        }

        return newPath;

    },

    /**
     * SUBSTITUTE TEXT
     *
     * VWFSUtils.substituteText("My {0} replace {1} text.", "55", "99");
     * Output: "My 55 replace 99 text."
     */
    substituteText: function () {
        var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

        for (
            var _len = arguments.length,
                args = Array(_len > 0 ? _len - 1 : 0),
                _key = 1;
            _key < _len;
            _key++
        ) {
            args[_key - 1] = arguments[_key];
        }

        if (!text || text === "" || args.length === 0) {
            return text;
        }

        var replacedText = text;

        for (var i = 0; i < args.length; i++) {
            replacedText = replacedText.replace("{" + i + "}", args[i]);
        }

        return replacedText;

    },

    /**
     * SUBSTITUTE PLACEHOLDER
     *
     * VWFSUtils.substitutePlaceholder("My ${param1} replace ${param2} text.", {"param1": "first","param2": "second"});
     * Output: "My first replace second text."
     */
    substitutePlaceholder: function (text, values) {

        if (!text || !values) {
            return text;
        }

        var replacedText = text;
        for (let property in values) {

            // REPLACE ONLY IF PROPERTY IS'T UNDEFINED
            if (values[property] !== undefined) {
                var regexp = new RegExp("\\${" + property + "}", "g");
                replacedText = replacedText.replace(regexp, values[property]);
            }
        }

        return replacedText;

    },

    /**
     * set Class u-hide if not set
     * @param component
     */
    setClassUHide: function (component) {
        if (!component.classList.contains("u-hide")) {
            component.classList.add("u-hide");
        }
    },

    /**
     * remove all class u-hide
     * @param component
     */
    removeClassUHide: function (component) {
        if (component.classList.contains("u-hide")) {
            component.classList.remove("u-hide");
            this.removeClassUHide(component);
        }
    },

    /**
     * finds closest parent with class name
     * @param element
     * @param className
     */
    findAncestor: function (element, className) {
        while ((element = element.parentElement) && !element.classList.contains(className)) ;
        return element;
    },

    /**
     * GET LOCAL STRING FROM FLOAT
     * Returns a localized () string from a float value. Used for price formatting.
     *
     * @param value
     * @param fractionDigits
     * @param isoLanguageCode {string}
     * @param isoCountryCode {string | string}
     */
    getLocalStringFromFloat: function (value, fractionDigits, isoLanguageCode, isoCountryCode) {
        //Set Default Value if fractionsDigits is undefined
        if (isoLanguageCode && isoLanguageCode.toString().length === 5) {
            isoLanguageCode = isoLanguageCode.toString().substring(0, 2);
        }

        if (typeof (fractionDigits) === "undefined") {
            fractionDigits = 2;
        }

        if (!isoLanguageCode) {
            isoLanguageCode = VWFSUtils.getMetaContent("datalayer-language");
            if (!isoLanguageCode) {
                console.debug("Error: utils.js: Missing meta-tag: 'datalayer-language'. Using 'en'.");
                isoLanguageCode = "en";
            }
        }

        if (!isoCountryCode) {
            isoCountryCode = VWFSUtils.getMetaContent("datalayer-countryCode");
            if (!isoCountryCode) {
                console.debug("Error: utils.js: Missing meta-tag: 'datalayer-countryCode'. Using 'EN'.");
                isoCountryCode = "EN";
            }
        }

        if (!isNaN(value)) {
            return parseFloat(value).toLocaleString(isoLanguageCode + "-" + isoCountryCode, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits
            });
        } else {
            return value;
        }
    },

    /**
     * GET META CONTENT
     * Returns the content of a meta-data tag defined by name.
     *
     * @param metaName
     */
    getMetaContent: function (metaName) {
        let pageNameElement = document.querySelector('meta[name="' + metaName + '"]');
        return pageNameElement ? pageNameElement.getAttribute('content') : "";
    },

    /**
     * GET COOKIE
     *
     * @param name
     */
    getCookie: function (name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) {
            return decodeURIComponent(parts.pop().split(";").shift());
        }
    },

    /**
     * LOAD SCRIPT
     */
    loadScript: function (src, async) {

        // CREATE SCRIPT-ELEMENT
        var scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.async = async;
        scriptElement.src = src

        var firstScriptElement = document.getElementsByTagName('script')[0];
        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);

    },

    /**
     * ADD URL PARAM
     *
     * @returns URL with new parameter added
     */
    addUrlParam: function (url, param, value) {
        if (url.startsWith("#")) {
            return url;
        }

        var hasUrlParams = url.indexOf("?") != -1;

        if (hasUrlParams && url.split("?")[1].indexOf(param) != -1) {

            // LOG ERROR
            console.error("Url already contains a parameter called '" + param + "'.");
            return url;

        } else {
            return url + (hasUrlParams ? "&" : "?") + param + "=" + encodeURIComponent(value);
        }

    },

    /**
     * STRIP HTML
     * Removes HTML-tags from a string.
     */
    stripHtml: function (text) {
        return !text ? null : text.replace(/(<([^>]+)>)/gi, "");
    },


    /**
     * GET FLOAT ATTRIBUTE
     *
     * null, undefined, "" ---> should give back defaultValue
     *
     * @param element
     * @param attributeName
     * @param default
     */
    getFloatAttribute: function (element, attributeName, defaultValue) {

        var value = defaultValue;

        if (element.hasAttribute(attributeName)) {

            var attribute = element.getAttribute(attributeName);
            if (attribute) {
                value = parseFloat(attribute);
            }

        }
        return value;

    },

    /**
     * GET INT ATTRIBUTE
     *
     * @param element
     * @param attributeName
     * @param default
     */
    getIntAttribute: function (element, attributeName, defaultValue) {

        var value = defaultValue;

        if (element.hasAttribute(attributeName)) {

            var attribute = element.getAttribute(attributeName);
            if (attribute) {
                value = parseInt(attribute);
            }

        }
        return value;

    },

    /**
     * GET STRING ATTRIBUTE
     *
     * @param element
     * @param attributeName
     * @param default
     */
    getStringAttribute: function (element, attributeName, defaultValue) {

        var value = defaultValue;

        if (element.hasAttribute(attributeName)) {

            var attribute = element.getAttribute(attributeName);
            if (attribute) {
                value = attribute;
            }

        }
        return value;

    },

    /**
     * GET RANDOM
     * Returns a randomized integer between specified min and max.
     *
     * @param min
     * @param max
     */
    getRandom: function (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    /**
     * CHECK IF DEFINED
     * Checks if a given path exists.
     *
     * This can be solved (or later removed) by using JavaScripts 'optional chaining'.
     * https://caniuse.com/?search=optional%20chaining
     * But the minifier is still not using it.
     *
     * @param {Object} object The object to look in.
     * @param {String} path The path as array
     */
    checkIfDefined: function (object, path) {
        if (!object || path.length === 0) {
            return false;
        }
        for (let item of path) {
            if (!object[item]) {
                return false;
            }
            object = object[item];
        }
        return true;
    },

    /**
     * GET OBJECT PATH EXISTS
     * Checks if a given path exists.
     *
     * Example:
     * var myObject = {"frieden":{"freude":{"eierkuchen":"0815"}}};
     * VWFSUtils.getObjectPathExists(myObject, "frieden.freude.eierkuchen");        // => true
     * VWFSUtils.getObjectPathExists(myObject, "frieden.freude");                    // => true
     * VWFSUtils.getObjectPathExists(myObject, "frieden.freude.pflaumenkuchen");        // => false
     *
     * This can be solved (or later removed) by using JavaScripts 'optional chaining'.
     * https://caniuse.com/?search=optional%20chaining
     * But the minifier is still not using it.
     *
     * @param {Object} object The object to look in.
     * @param {String} path The path seperated by dots
     */
    getObjectPathExists: function (object, path) {

        if (!object || path.length === 0) {
            return false;
        }

        var pathArray = path.split(".");
        for (let item of pathArray) {
            if (!object[item]) {
                return false;
            }
            object = object[item];
        }
        return true;

    },

    /**
     * GET OBJECT PATH
     * Gets the value of an object by path given as string.
     *
     * Example:
     * var myObject = {"frieden":{"freude":{"eierkuchen":"0815"}}};
     * VWFSUtils.getObjectPath(myObject, "frieden.freude.eierkuchen");    // => 0815
     * VWFSUtils.getObjectPath(myObject, "frieden.freude");                // => Object { eierkuchen: "0815" }
     *
     * This can be solved (or later removed) by using JavaScripts 'optional chaining'.
     * https://caniuse.com/?search=optional%20chaining
     * But the minifier is still not using it.
     *
     * @param {Object} object The object to look in.
     * @param {String} path The path seperated by dots
     */
    getObjectPath: function (object, path) {

        if (!object || path.length === 0) {
            return false;
        }

        var pathArray = path.split(".");
        for (let item of pathArray) {
            if (!object[item]) {
                return false;
            }
            object = object[item];
        }
        return object;

    },

    getWeekdayMap: function () {
        return {
            0: "MONDAY",
            1: "TUESDAY",
            2: "WEDNESDAY",
            3: "THURSDAY",
            4: "FRIDAY",
            5: "SATURDAY",
            6: "SUNDAY"
        }
    },

    /**
     * IS IOS
     * Checks if device is an iOS-device.
     */
    isIOS: function () {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    },

    /**
     * check if a component is to be shown by checking parent elements as well.
     * no parents above <section> or class of site nav are checked
     *
     * @param component         component that is checked
     * @param carJsonObject     car json
     * @returns {boolean|*}     true if component is to be shown
     */
    isComponentShown: function (component, carJsonObject) {
        let showComponent = true;
        let examinedComponent = component;

        while (true) {
            if (examinedComponent.showLogic) {
                showComponent = examinedComponent.showLogic.isComponentShown(carJsonObject)
            }

            // stop on first "false" or when reaching section or site nav
            if (!showComponent
                || examinedComponent.tagName === "SECTION"
                || examinedComponent.classList.contains("c-site-nav__nav")
                // ultimate limit for while loop
                || examinedComponent.tagName === "BODY") {
                break;
            }

            // check parent in next loop
            examinedComponent = examinedComponent.parentElement;
        }

        return showComponent;
    },

    /**
     * This function set given value to Local storage with a TTL
     * @param {string} key - A key to identify the value.
     * @param {any} value - A value associated with the key.
     * @param {number} ttl- Time to live in seconds.
     */
    setItemToLocalStorage: function (key, value) {
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: (Math.floor(Date.now() / 1000)) + VWFSUtils.getTTL(),
        }
        localStorage.setItem(key, JSON.stringify(item))
    },

    /**
     * this function return stored data from localstorage based on given key, if the data is not expired according to the configured TTL
     * @param {string} key - A key to identify the data.
     * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
     */
    getItemFromLocalStorage: function (key) {
        const data = localStorage.getItem(key);
        if (!data) {     // if no value exists associated with the key, return null
            return null;
        }

        const item = JSON.parse(data);
        if (item.expiry === null || item.expiry === undefined) {
            localStorage.removeItem(key);
            return null;
        }
        // compare the expiry time of the item with the current time
        if ((Math.floor(Date.now() / 1000)) > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        // return data if not expired
        return item.value;
    },

    /**
     * This function set given value to session storage with a TTL
     * @param {string} key - A key to identify the value.
     * @param {any} value - A value associated with the key.
     */
    setItemToSessionStorage: function (key, value) {
        window.sessionStorage.setItem(key, value)
    },

    /**
     * this function return stored data from session storage based on given key
     * @param {string} key - A key to identify the data.
     * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
     */
    getItemFromSessionStorage: function (key) {
        return window.sessionStorage.getItem(key);
    },

    /**
     * this function returns the Pois from a DIS request that were previously stored in the SessionStorage
     * @param {string} disConfig - name of dealerInformationServiceConfig
     * @return {array} returns an array with points of Interest
     */

    getDealerInformationServicePoisFromSessionStorage(disConfig) {
        if (disConfig) {
            if (window.sessionStorage.getItem("dis_request_" + disConfig)) {
                let dealerInformationServiceRequest = JSON.parse(window.sessionStorage.getItem("dis_request_" + disConfig));
                if (dealerInformationServiceRequest.lat || dealerInformationServiceRequest.lng || dealerInformationServiceRequest.radius) {
                    return null;
                } else if (dealerInformationServiceRequest.disConfigName && dealerInformationServiceRequest.disConfigName === disConfig) {
                    return dealerInformationServiceRequest.pointsOfInterest;
                }
            }
        }
    },

    /**
     * this function saves the pois and the name of the DealerInformationServiceConfig from a recent request
     * @param {string} disConfig - name of dealerInformationServiceConfig
     * @param {array} pointsOfInterest - array with points of interest
     * @param {coordinates} lat - lat coordinates from centerMarker
     * @param {coordinates} lng - lng coordinates from centerMarker
     * @param {string} radius - radius
     */
    saveDealerInformationServicePoisToSessionStorage(disConfig, pointsOfInterest, lat, lng, radius) {
        if (disConfig) {
            let disRequest = {};
            disRequest["disConfigName"] = disConfig;
            disRequest["pointsOfInterest"] = pointsOfInterest;

            if (lat) {
                disRequest["lat"] = lat;
            }
            if (lng) {
                disRequest["long"] = lng;
            }
            if (radius) {
                disRequest["radius"] = radius;
            }
            window.sessionStorage.setItem("dis_request_" + disConfig, JSON.stringify(disRequest));
        }
    },


    /**
     * this function checks if JSON Object is completely Empty by recursion
     * @param obj object
     */
    isObjectEmpty(obj) {
        for (let key in obj) {
            //if the value is 'object'
            if (obj[key] instanceof Object) {
                if (VWFSUtils.isObjectEmpty(obj[key]) === false) return false;
            }
            //if value is string/number
            else {
                //if array or string have length is not 0.
                if (obj[key].length !== 0) return false;
            }
        }
        return true;
    }
};
