"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LinkHandler = /** @class */ (function () {
    function LinkHandler(link) {
        var _this = this;
        this.link = link;
        // mark as processed
        this.link.classList.add(LinkHandler.LINK_HANDLER_CLASS);
        if (this.link.href && this.link.href !== '#') {
            this.link.href = this.processPassThroughParameters(this.link.href, this.link.dataset.passThroughParameter, false);
        }
        if (this.link.dataset.obfuscatedhref) {
            this.link.dataset.obfuscatedhref = this.processPassThroughParameters(this.link.dataset.obfuscatedhref, this.link.dataset.passThroughParameter, true);
        }
        if (this.link.dataset.href2) {
            this.link.dataset.href2 = this.processPassThroughParameters(this.link.dataset.href2, this.link.dataset.passThroughParameter2, false);
        }
        if (this.link.dataset.obfuscatedhref2) {
            this.link.dataset.obfuscatedhref2 = this.processPassThroughParameters(this.link.dataset.obfuscatedhref2, this.link.dataset.passThroughParameter2, true);
        }
        if (this.link.classList.contains('js-checkout-button') && this.link.dataset.checkoutPath) {
            new VWFS.CheckoutManager(this.link).openLink();
        }
        else if (this.link.dataset.modalRef || this.link.dataset.disclaimerRef) {
            this.link.addEventListener('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                new VWFS.ModalManager(_this.link).openModal();
            });
        }
        else if (this.link.dataset.obfuscatedhref) {
            link.classList.add(LinkHandler.LINK_HANDLER_CLASS);
            link.addEventListener('click', function (event) {
                event.preventDefault();
                var obfuscatedhref = link.getAttribute('data-obfuscatedhref');
                var target = link.hasAttribute('target') ? link.getAttribute('target') : '_self';
                window.open(window.atob(obfuscatedhref), target);
            });
        }
    }
    LinkHandler.prototype.substituteURLPlaceholders = function (pageUrl, href) {
        //substitute placeholders which only contain characters from ranges a-z, A-Z, 0-9-_
        var regex = /\$\(([a-zA-Z0-9-_]*)\)/g;
        href.pathname = href.pathname.replace(regex, function (_match, parameterName) { return pageUrl.searchParams.get(parameterName) || ''; });
        return href;
    };
    LinkHandler.prototype.processPassThroughParameters = function (href, passThroughParameter, obfuscated) {
        var pageUrl = new URL(window.location.href);
        if (obfuscated) {
            href = window.atob(href);
        }
        var linkUrl = new URL(href, new URL(window.location.origin));
        linkUrl.href = this.substituteURLPlaceholders(pageUrl, linkUrl).toString();
        if (passThroughParameter) {
            passThroughParameter
                .trim()
                .split(',')
                .forEach(function (parameterName) {
                if (pageUrl.searchParams.has(parameterName)) {
                    var urlWindowParamValue = pageUrl.searchParams.get(parameterName);
                    if (linkUrl.searchParams.has(parameterName)) {
                        linkUrl.searchParams.set(parameterName, urlWindowParamValue);
                    }
                    else {
                        linkUrl.searchParams.append(parameterName, urlWindowParamValue);
                    }
                }
            });
        }
        return obfuscated ? window.btoa(linkUrl.href) : linkUrl.href;
    };
    LinkHandler.LINK_HANDLER_CLASS = 'js-nbw-link-handler-ready';
    return LinkHandler;
}());
exports.default = LinkHandler;
/*
 * ON DOCUMENT LOADED and many more
 */
;
['DOMContentLoaded', 'reInitLinks', 'productFinderLoaded', 'carSearchLoaded', 'googleMapLoaded', 'carDetailsLoaded', 'xfLoaded'].forEach(function (eventName) {
    console.debug('INFO LinkHandler loaded');
    document.addEventListener(eventName, function () {
        document
            .querySelectorAll("a[data-disclaimer-ref]:not(.".concat(LinkHandler.LINK_HANDLER_CLASS, "),a[data-modal-ref]:not(.").concat(LinkHandler.LINK_HANDLER_CLASS, "), a[href*='$('][href*=')']:not(.").concat(LinkHandler.LINK_HANDLER_CLASS, "), a.js-checkout-button:not(.").concat(LinkHandler.LINK_HANDLER_CLASS, "), a[data-pass-through-parameter]:not(.").concat(LinkHandler.LINK_HANDLER_CLASS, "), a[data-obfuscatedhref]:not(.").concat(LinkHandler.LINK_HANDLER_CLASS, ")"))
            .forEach(function (link) { return new LinkHandler(link); });
    });
    document.querySelectorAll('button.js-tooltip').forEach(function (button) {
        button.addEventListener('mouseover', function () {
            setTimeout(function () {
                document
                    .querySelectorAll(".tippy-popper a[data-disclaimer-ref]:not(.".concat(LinkHandler.LINK_HANDLER_CLASS, ")"))
                    .forEach(function (disclaimerLink) { return new LinkHandler(disclaimerLink); });
            }, 10);
        });
    });
});
