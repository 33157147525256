window.VWFS = window.VWFS || {};
VWFS.FootnoteLinks = function (footnoteLink) {

    /**
     * INIT
     */
    this.init = function () {

        // ADD READY-CLASS
        footnoteLink.classList.add("js-nbw-foot-note-ready");

        // GET FOOTNOTE-REFERENCE
        var footnoteRef = footnoteLink.getAttribute('data-footnote-ref');

        // GET FOOTNOTE-MODAL
        this.modal = document.querySelector('#' + footnoteRef);
        if (this.modal) {

            // ADD CLICK-LISTENER
            footnoteLink.addEventListener('click', event => {
                event.preventDefault();

                // GET FOOTNOTE AND SHOW IT
                this.modal.dispatchEvent(new Event("bronson_modal_open"));

            });

            // GET CLOSE-BUTTON
            var modalCloseButton = this.modal.querySelector(".js-modal-close-btn");

            // ADD CLICK-LISTENER
            modalCloseButton.addEventListener('click', event => {

                // HIDE MODAL
                this.modal.dispatchEvent(new Event("bronson_modal_close"));

            });

        } else {

            console.debug("Error: FootnoteLinks.js: Cant find modal '#" + footnoteRef + "' for footnote.");

        }

    }

};


/**
 * ON DOCUMENT LOADED
 */
['DOMContentLoaded', 'reInitFootnotes'].forEach(function (eventName) {
    console.debug("INFO FootnoteLink.js loaded");

    // ADD FUNCTION TO BOTH EVENTS
    document.addEventListener(eventName, function () {

        // FOR ALL UNPROCESSED FOOTNOTE-LINKS
        document.querySelectorAll(".js-footnote-link:not(.js-nbw-foot-note-ready)").forEach(
            function (footnoteLink) {
                new VWFS.FootnoteLinks(footnoteLink).init();
            }
        );

    });
});
