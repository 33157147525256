"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var InstructionStepper = /** @class */ (function () {
    function InstructionStepper() {
    }
    InstructionStepper.prototype.updateStyleClass = function (component) {
        if (component.querySelector("div[is-active='true']") != null) {
            component.classList.add(InstructionStepper.CSS_CLASS_IS_ACTIVE);
        }
        else if (component.querySelector("div[is-active='false']") != null) {
            component.classList.remove(InstructionStepper.CSS_CLASS_IS_ACTIVE);
        }
    };
    InstructionStepper.CSS_CLASS_IS_ACTIVE = 'is-active';
    InstructionStepper.CSS_CLASS_INSTRUCTION_STEPPER_STEP = '.c-instruction-stepper li.c-instruction-stepper__step';
    return InstructionStepper;
}());
exports.default = InstructionStepper;
var instructionStepperInstance = new InstructionStepper();
if (!window.VWFS) {
    // @ts-ignore
    window.VWFS = {};
}
window.VWFS.instructionStepper = instructionStepperInstance;
['DOMContentLoaded', 'xfLoaded'].forEach(function (eventName) {
    document.addEventListener(eventName, function () {
        console.debug('INFO InstructionStepper.js loaded');
        document.querySelectorAll(InstructionStepper.CSS_CLASS_INSTRUCTION_STEPPER_STEP).forEach(function (component) {
            new InstructionStepper().updateStyleClass(component);
        });
    });
});
