console.debug("INFO VWFSAnalyticsUtils.js loaded");
/**
 * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
 */
window.VWFSAnalyticsUtils = window.VWFSAnalyticsUtils || {
    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     *
     * Set value to key in placeholder of datalayer
     */
    setDataInLayer: function (key, value) {
        if (value) {
            let dl = window.tracking;
            let p = key.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '');
            return VWFSAnalyticsUtils.setPath(dl, p, value);
        }
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setPath: function (object, path, value) {
        path.split('.').reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    sendAnalyticsEvent: function (type) {
        if (typeof _satellite !== "undefined" && _satellite) {
            _satellite.track ? _satellite.track(type) : console.debug("Analytics: _satellite.track is not loaded!");
            _satellite.logger ? _satellite.logger.info("Analytics: Event of type '" + type + "' was fired!") : console.debug("Analytics: _satellite.logger is not loaded!");
        } else {
            console.debug("Analytics: _satellite library was not loaded!");
        }
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    sendAnalyticsFilterReset: function (name) {
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.linkInformation", name);
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventType", "interAction");
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventAction", "Success");
        VWFSAnalyticsUtils.sendAnalyticsEvent("interaction");
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setCoreAnalytics: function (coreData) {
        VWFSAnalyticsUtils.setDataInLayer("core.pageInfo.pageName", coreData.pageName);
        this.setLinkInfo();
        VWFSAnalyticsUtils.setDataInLayer("core.pageInfo.language", coreData.lang);
        VWFSAnalyticsUtils.setDataInLayer("core.pageInfo.market", coreData.country);

        VWFSAnalyticsUtils.setDataInLayer("core.category.primaryCategory", VWFSUtils.getMetaContent('datalayer-sitePrimaryCategory') !== null ? VWFSUtils.getMetaContent('datalayer-sitePrimaryCategory') : "");
        VWFSAnalyticsUtils.setDataInLayer("core.category.secondaryCategory", VWFSUtils.getMetaContent('datalayer-siteSecondaryCategories') !== null ? VWFSUtils.getMetaContent('datalayer-siteSecondaryCategories') : "");

        VWFSAnalyticsUtils.setDataInLayer("core.attributes.viewChange", coreData.pageName);
        VWFSAnalyticsUtils.setDataInLayer("core.attributes.brand", coreData.brandDesign);
        VWFSAnalyticsUtils.setDataInLayer("core.stagingEnvironment", coreData.stage);

        VWFSAnalyticsUtils.setDataInLayer("design.browserResolutionBreakpoint", coreData.breakPoint);

        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventType", "pageView");
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventAction", "Success");
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setFilters: function (filters, results, type) {
        //Filter array should be reset instead of completely deletion
        window.tracking.filter = [];
        for (let i = 0; i < filters.length; i++) {
            VWFSAnalyticsUtils.setDataInLayer("filter[" + i + "]", filters[i]);
            //- 1, because Rate & Price count as one filter
            VWFSAnalyticsUtils.setDataInLayer("filter.count", filters.length - 1);
        }
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventType", type);
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventAction", "Success");

        if (type === "pageView") {
            this.setLinkInfo();
        }

        if (results != null) {
            VWFSAnalyticsUtils.setDataInLayer("search.results", results);
        }
        VWFSAnalyticsUtils.sendAnalyticsEvent("interaction");
    },


    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setFilter: function (filter, index) {
        VWFSAnalyticsUtils.setDataInLayer("filter[" + index + "]", filter);
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    addFilter: function (filter) {
        VWFSAnalyticsUtils.setDataInLayer("filter[" + this.getFilters().length + "]", filter);
    },


    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    getFilters: function () {
        return window.tracking.filter;
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setLinkInfo() {

        let info;

        switch (window.tracking.core.pageInfo.pageName) {
            case "ACS quick search":
                info = "Initial";
                break;
            case "ACS result list":
                info = "Result list";
                break;
            case "ACS vehicle details":
                info = "Vehicle details";
                break;
            default:
                info = "";
                break;
        }

        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.linkInformation", info)
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setCheckoutFrom: function (product) {
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.linkInformation", "ACS to checkout");

        // addOns as filter

        VWFSAnalyticsUtils.setDataInLayer("filter.count", 0);
        VWFSAnalyticsUtils.setDataInLayer("search.results", 1);
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventType", "Vehicle Information");
        VWFSAnalyticsUtils.setDataInLayer("event[0].eventInfo.eventAction", "Provided");

        setTimeout((function () {
            VWFSAnalyticsUtils.sendAnalyticsEvent("interaction");
        }), 300);
    },

    /**
     * @deprecated The method should not be used for new code. Related to legacy PSF, PLP, and PDP.
     */
    setDealerData(dealerData) {
        if (Object.keys(dealerData.address).length) {
            VWFSAnalyticsUtils.setDataInLayer("dealerData.address.street", dealerData.address.street);
            VWFSAnalyticsUtils.setDataInLayer("dealerData.address.zipCode", dealerData.address.zipCode);
            VWFSAnalyticsUtils.setDataInLayer("dealerData.address.city", dealerData.address.city);
            VWFSAnalyticsUtils.setDataInLayer("dealerData.address.state", dealerData.address.state);
        }
        VWFSAnalyticsUtils.setDataInLayer("dealerData.companyId", dealerData.companyId);
        VWFSAnalyticsUtils.setDataInLayer("dealerData.companyName", dealerData.companyName);
        VWFSAnalyticsUtils.setDataInLayer("dealerData.regionId", dealerData.regionId);
        VWFSAnalyticsUtils.setDataInLayer("dealerData.KVPS", dealerData.KVPS);
    }
};
