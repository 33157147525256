/**
 * Logic for all main sections on a page which are directly under the "main" div
 * Changed by iSo 29.09.2020
 */
document.addEventListener('DOMContentLoaded', function () {
    console.debug("INFO sections.js loaded");
    // Get all sections
    var sections = document.querySelectorAll('main div.section');
    var sectionsLength = sections.length;
    sections.forEach(function (section, index) {
        // If there is no divider element in the section, we know that it was hidden in the dialog
        var sectionDivider = section.querySelector('section + .c-divider, section + .nbw-divider');
        // Check if this is last section and if we have a divider
        if (sectionsLength !== index + 1 && sectionDivider) {
            // Compare current and next element background colors
            var sectionElement = section.firstElementChild;
            var currentBackground = sectionElement.hasAttribute("data-theme") ? 'grey' : 'white';
            var nextBackground = sections[index + 1].firstElementChild.hasAttribute("data-theme") ? 'grey' : 'white';
            if (currentBackground !== nextBackground) {
                // Hide divider if the background color of the next section is the same
                sectionDivider.classList.add('u-hide');
            }
        } else if (sectionDivider) {
            // Hide divider if it's the last section on the page
            sectionDivider.classList.add('u-hide');
        }

    });
});
