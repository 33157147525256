console.debug("INFO main.js loaded");
document.addEventListener("DOMContentLoaded", function () {

  // DMP-3696 Click on search focusses input
  var searchBtn = document.querySelector(".js-search-btn");
  if (searchBtn) {
    var searchInput = document.querySelector(".js-search-input");
    searchBtn.addEventListener("click", function (event) {
      // create invisible dummy input to receive the focus first
      var fakeInput = document.createElement('input')
      fakeInput.setAttribute('type', 'text')
      fakeInput.style.position = 'absolute'
      fakeInput.style.opacity = 0
      fakeInput.style.height = 0
      fakeInput.style.fontSize = '16px' // disable auto zoom
      // you may need to append to another element depending on the browser's auto
      // zoom/scroll behavior
      document.body.prepend(fakeInput)
      // focus so that subsequent async focus will work
      fakeInput.focus()
      setTimeout(function () {
        searchInput.focus()
        fakeInput.remove()
      }, 100);
    });
  } else {
    console.debug("Debug: main.js: searchBtn is missing.");
  }

  // DMP-1103 removes empty .o-component-wrappers from html
  var o_component_wrappers = document.querySelectorAll(".o-component-wrapper");
  o_component_wrappers.forEach(function (item) {
    if (item.innerHTML.trim() === '') {
      item.remove();
    }
  });

});

document.addEventListener('bronson_panelchanged', function () {
  // do an additional resize-calculation for compare-and-select within the tabitem when the tab is changed
  document.querySelectorAll(".c-tabs__content[aria-hidden='false']").forEach(
      function (contentTabItem) {
        contentTabItem.querySelectorAll(".js-compare-and-select").forEach(
            function (compareAndSelect) {
              window.bronson.libs.Flickity(compareAndSelect).resize();
            });
      });
});

['DOMContentLoaded'].forEach(function (type) {
  document.addEventListener(type, function () {
    // DMP-12554 Cookie Consent Link
    // Open cookie consent tool by clicking on a link with href '#cookieconsent#
    var cookieConsentLinks = document.querySelectorAll(
        'a[href="#cookieconsent"]');
    cookieConsentLinks.forEach(function (cookieConsentLink) {
      cookieConsentLink.addEventListener('click', function (event) {
        event.preventDefault();
        if (window.CookieControl) {
          window.CookieControl.open();
        }
      });
    });
  });
});

function refreshHash() {
  if (!navigator.userAgent.includes("Firefox")) {
    var hash = window.location.hash;
    window.location.hash = '';
    window.location.hash = hash;
  }
}

window.onload = () => {
  if (!window.location.hash) {
    return;
  }

  refreshHash();

  var panels = document.querySelectorAll(".js-accordion__panel");
  if (panels && panels.length > 0) {
    var observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === "class"
            && mutation.target.classList.contains("is-collapsed")) {
          refreshHash()
          observer.disconnect();
        }
      })
    })

    observer.observe(panels[panels.length - 1], {attributes: true});
    setTimeout(() => observer.disconnect(), 2000);
  }
};

document.addEventListener("trackingParamsSet", function () {
  // DMP-6402 logic to manipulate URL parameters
  var links = document.querySelectorAll("a");
  links.forEach(function (link) {
    var href = link.getAttribute("href");
    // If the href is empty or doesn't contain query params, skip this link
    if (!href || (href && href.indexOf("?") === -1)) {
      return;
    }

    // Split the whole link into the blank link and the parameters
    var parameterString = href.split("?")[1];
    var newLink = href.split("?")[0] + "?";
    var parameters = parameterString.split("&");

    // Iterate over each parameter
    parameters.forEach(function (parameter, index) {
      // Split parameters into key value pairs
      var key = parameter.split("=")[0];
      var value = parameter.split("=")[1];
      if (!key || !value) {
        return;
      }
      var isPlaceholder = value.startsWith("((") && value.endsWith("))");

      // Skip parameter if it contains a placeholder, but there is not window.tracking object
      if (isPlaceholder && !window.tracking) {
        return;
      }

      // Logic to replace the placeholder with the actual analytics value
      if (isPlaceholder) {
        var clearValue = value.substring(2, value.length - 2);
        var dataLayerValue = window.tracking.params[clearValue];
        if (dataLayerValue) {
          value = dataLayerValue;
        } else {
          return;
        }
      }

      // Append the parameter to the blank link
      newLink += key + "=" + value + "&";
    });

    // Trim the link if there is an unnecessary "&" oder "?" at the end
    newLink = newLink.endsWith("?") || newLink.endsWith("&") ? newLink.substr(0,
        newLink.length - 1) : newLink;

    // Set the new href on the link
    link.setAttribute("href", newLink);

  });
});
