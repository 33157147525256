window.VWFS = window.VWFS || {};
VWFS.MapHelper = (function () {

	console.debug("INFO MapHelper.js loaded");

	let isLoading = false;
	let isLoaded = false;
	let subscribers = [];

	let hasGeoLocation = false;
	let geoLocation = null;
	let geoLocationSubscribers = [];

	/**
	 * LOAD AND SUBSCRIBE
	 */
	function loadLibrary(callback) {

		if(!isLoaded) {

			if(!isLoading) {

				console.debug("DEBUG: Load HereMap Library.");

				let numScriptLoaded = 0;

				let hereMapCssBronson = document.createElement('link');
				hereMapCssBronson.href = getHereMapsBronsonUrl();
				hereMapCssBronson.type = "text/css"
				hereMapCssBronson.rel = "stylesheet"
				hereMapCssBronson.onload = () => {
					scriptLoaded()
				};

				document.head.appendChild(hereMapCssBronson);

				let scriptTag = document.createElement('script');
				scriptTag.src = "/etc.clientlibs/bluelabel/clientlibs/clientlib-poiselector/resources/hereMap.3.1.35.3.min.js";
				scriptTag.type = "text/javascript"
				scriptTag.charSet = "utf-8"
				scriptTag.onload = () => {
					scriptLoaded()
				};
				document.head.appendChild(scriptTag);


				function scriptLoaded() {
					numScriptLoaded++;
					// plus one because of the css style
					if(numScriptLoaded >= 2) {
						console.debug("DEBUG: HereMap Library loaded.");

						// SET FLAG
						isLoaded = true;

						// NOTIFY SUBSCRIBERS
						setTimeout((function () {
							subscribers.forEach(subscriber => subscriber.call());
						}), 100);

					}
				}

				// SET FLAG LOADING
				isLoading = true;

			}

			// ADD SUBSCRIBER TO LIST
			subscribers.push(callback);

		} else {

			callback.call();

		}

	}


	/**
	 * GET GEO-LOCATION
	 */
	function getGeoLocation(callback, requestGeoLocationAgain) {

		if(!hasGeoLocation || requestGeoLocationAgain) {

			if (navigator.geolocation) {

				navigator.geolocation.getCurrentPosition(function (position) {

					geoLocation = position;

					hasGeoLocation = true;

					// NOTIFY SUBSCRIBERS
					geoLocationSubscribers.forEach(subscriber => subscriber.call({}, geoLocation));

				}, function (error) {

					hasGeoLocation = false;

					// NOTIFY SUBSCRIBERS
					geoLocationSubscribers.forEach(subscriber => subscriber.call());

				});

			} else {

				hasGeoLocation = false;

				console.debug("DEBUG: navigator.geolocation not supported.");
			}


			// ADD SUBSCRIBER TO LIST
			geoLocationSubscribers.push(callback);

		} else {

			callback.call(geoLocation);

		}

	}

	function getHereMapsBronsonUrl() {
		const mapComponent = document.querySelector(".js-poiselector") || document.querySelector(".js-heremap") || null;

		return mapComponent ?
			mapComponent.getAttribute("data-map-bronson-url") : "";
	}

	return {
		loadLibrary: loadLibrary,
		getGeoLocation: getGeoLocation

	};

})();
