window.VWFS = window.VWFS || {};
VWFS.ACSUtils = VWFS.ACSUtils || {};
VWFS.ACSUtils.Placeholder = {
	replacePlaceholderInText: function (text, productOfferJson, decimalValues, language, countryCode) {
		let substitutions = {};

		if (this.lookupFinancialProduct(productOfferJson, "privatelease")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "privatelease", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "operatelease")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "operatelease", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "financelease")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "financelease", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "autocredit")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "autocredit", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "classiccredit")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "classiccredit", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "cashpurchase")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "cashpurchase", productOfferJson, decimalValues, language, countryCode)
		}
		if (this.lookupFinancialProduct(productOfferJson, "subscription")) {
			VWFS.ACSUtils.Placeholder.setSubstitutionsForProduct(substitutions, "subscription", productOfferJson, decimalValues, language, countryCode)
		}

		substitutions.brand = productOfferJson.vehicleData.model.brand;
		substitutions.model = productOfferJson.vehicleData.model.name;

		let date;
		if (isNaN(productOfferJson.offer.validityEndDate)) {
			date = new Date(productOfferJson.offer.validityEndDate);
		} else {
			date = new Date(parseInt(productOfferJson.offer.validityEndDate));
		}
		substitutions.validityEndDate = date.toLocaleString([], {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		});

		return VWFSUtils.substitutePlaceholder(text, substitutions);
	},

	setSubstitutionsForProduct(substitutions, productType, productOfferJson, decimalValues, language, countryCode) {
		const financialOffer = this.lookupFinancialProduct(productOfferJson, productType);

		substitutions[productType + ".downPayment"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.downPaymentAmount, decimalValues, language, countryCode);
		substitutions[productType + ".balloonPayment"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.balloonPayment, decimalValues, language, countryCode);
		substitutions[productType + ".installment"] = VWFSUtils.getLocalStringFromFloat((financialOffer.calculation.monthlyTotalRateAmount || financialOffer.calculation.monthlyLeasingAmount), decimalValues, language, countryCode);
		substitutions[productType + ".effectiveInterestRate"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.effectiveInterestRate, 2, language, countryCode);
		substitutions[productType + ".nominalInterestRate"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.nominalInterestRate, 2, language, countryCode);
		substitutions[productType + ".duration"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.duration, 0, language, countryCode);
		substitutions[productType + ".yearlyMileage"] = VWFSUtils.getLocalStringFromFloat(financialOffer.calculation.yearlyMileage, 0, language, countryCode);
		substitutions[productType + ".costOfOwnership"] = VWFSUtils.getLocalStringFromFloat(financialOffer.costOfOwnership, decimalValues, language, countryCode);
	},

	lookupFinancialProduct(productOfferJson, productType) {
		if (productOfferJson.financialProducts) {
			return productOfferJson.financialProducts.filter(function (product) {
				return product.calculation.product === productType;
			})[0];
		} else if (productOfferJson.financialProduct) {
			if (productOfferJson.financialProduct.calculation.product === productType) {
				return productOfferJson.financialProduct
			}
		}
	}
}
