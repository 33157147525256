window.VWFS = window.VWFS || {};
VWFS.AemDataServiceMediator = function () {
	console.debug("INFO AemDataServiceMediator.js loaded");
	this.VehicleServices = {
        ASS: "ASS",
        RCOS: "RCOS",
        CSbR: "CSbR"
    }

	var SERVLET_ENDPOINT_CSbR = "/api/carpool/car";
	var SERVLET_ENDPOINT_ASS = "/api/ass/car";
	var SERVLET_ENDPOINT_ASS_PRODUCT = "/api/ass/product";
	var SERVLET_ENDPOINT_RCOS = "/api/rcos/car";
    var SERVLET_ENDPOINT_RCOS_ADDITIONAL_PRODUCTS = "/api/rcos/additionalProducts";

	/**
	 * REQUEST VEHICLE DATA FROM CAR POOL
	 * This function takes a car-uuid and performs the following steps:
	 * 1. Request to the AEM-Data-Service
	 * 2. writes the response-data (json) into the Session Storage of the browser.
	 * 3. Fires an event
	 *
	 * @param carUuid cant be used for context RCOS, because we have no id.
	 * @param language
	 * @param vehicleService
	 * @param filterString
	 */
	this.requestVehicleData = function (carUuid, language, vehicleService, filterString) {

        if (!vehicleService) {
            vehicleService = this.VehicleServices.CSbR;
        }

		// GET API-URL (DEPENDING ON ENVIRONMENT)
		var aemApiUrl = VWFSUtils.getMetaContent('aem-api-url') || "";
		if (aemApiUrl) {
			aemApiUrl = "https://" + aemApiUrl;
		} else {

			console.debug("Error: AemDataServiceMediator.js: Missing Api-URL.");

			// Terminate only on non-local environment since there is no api-url provided.
			if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {

				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {carUuid: carUuid}});
				});

				return false;
			}
		}

		let requestUrl = aemApiUrl;
		switch (vehicleService) {
            case this.VehicleServices.ASS:
                requestUrl += SERVLET_ENDPOINT_ASS
                break;
            case this.VehicleServices.RCOS:
                requestUrl += SERVLET_ENDPOINT_RCOS
                break;
            case this.VehicleServices.CSbR:
                requestUrl += SERVLET_ENDPOINT_CSbR
                break;
			default:
				console.debug("Error: AemDataServiceMediator.js: Wrong vehicle Service.");

				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {carUuid: carUuid}});
				});

				return false;
		}

		if (carUuid) {
			requestUrl = VWFSUtils.addUrlParam(requestUrl, "car_uuid", carUuid);
		} else {
            console.debug("Error: AemDataServiceMediator.js: Missing carUUID.");

            // FIRE EVENT
            document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
                VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {carUuid: carUuid}});
            });

            return false;
        }

        if (filterString) {
            requestUrl = VWFSUtils.addUrlParam(requestUrl, "filters", filterString);
        }

        if (VWFSUtils.getURLParameter("ncbp_corporate_customer_id")) {
            let a = VWFSUtils.getURLParameter("ncbp_corporate_customer_id");
            requestUrl = VWFSUtils.addUrlParam(requestUrl, "ncbp_corporate_customer_id", a);
        }

        if (VWFSUtils.getURLParameter("ncbp_dis_config")) {
            let ncbp_dis_config = VWFSUtils.getURLParameter("ncbp_dis_config");
            requestUrl = VWFSUtils.addUrlParam(requestUrl, "ncbp_dis_config", ncbp_dis_config);
        }

        requestUrl += "&from=" + window.location.protocol + "//" + window.location.host

        if (vehicleService === this.VehicleServices.CSbR) {
            if (language) {
                requestUrl += "&language=" + language
            } else {
                console.debug("Error: AemDataServiceMediator.js: Missing Language.");

                // FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {carUuid: carUuid}});
				});

				return false;
			}
		}


		// PERFORM REQUEST
		var request = new XMLHttpRequest();
		request.addEventListener("load", onVehicleDetailsLoaded);
		request.open("GET", requestUrl, true);
		request.send();

	};

	/**
	 * REQUEST VEHICLE DATA FROM CAR POOL
	 * This function takes a vehicle Id and a customer type and performs the following steps:
	 * 1. Request to the AEM-Data-Service
	 * 2. Reload the page with the response Id
	 *
	 * @param vehicleId
	 * @param customerType
	 */
	this.requestProductUsingVehicleId = function (vehicleId, customerType) {
		// GET API-URL (DEPENDING ON ENVIRONMENT)
		var aemApiUrl = VWFSUtils.getMetaContent('aem-api-url') || "";
		if (aemApiUrl) {
			aemApiUrl = "https://" + aemApiUrl;
		} else {

			console.debug("Error: AemDataServiceMediator.js: Missing Api-URL.");
			// Terminate only on non-local environment since there is no api-url provided.
			if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {}});
				});
				return false;
			}
		}
		let requestUrl = aemApiUrl + SERVLET_ENDPOINT_ASS_PRODUCT;
		if (vehicleId && customerType) {
			requestUrl = VWFSUtils.addUrlParam(requestUrl, "vehicle-id", vehicleId);
			requestUrl = VWFSUtils.addUrlParam(requestUrl, "customer-type", customerType);
		} else {
			console.debug("Error: AemDataServiceMediator.js: Missing Vehicle Id.");
			// FIRE EVENT
			document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
				VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {}});
			});
            return false;
        }

        // PERFORM REQUEST
        var request = new XMLHttpRequest();
        request.addEventListener("load", onProductUuidLoaded);
        request.open("GET", requestUrl, true);
        request.send();
    };


    /**
	 * REQUEST Additional Products from RCOS
	 * This function takes a caruuid, that is a combination of 4 different parameters, and performs the following steps:
	 * 1. Request to the AEM-Data-Service
	 * 2. Reload the page with the response Id
	 *
	 * @param carUuid
	 * @param additionalProductsService
	 */
	this.requestAdditionalProducts = function (carUuid, additionalProductsService) {
		// GET API-URL (DEPENDING ON ENVIRONMENT)
		var aemApiUrl = VWFSUtils.getMetaContent('aem-api-url') || "";
		if (aemApiUrl) {
			aemApiUrl = "https://" + aemApiUrl;
		} else {
			console.debug("Error: AemDataServiceMediator.js: Missing Api-URL.");
			// Terminate only on non-local environment since there is no api-url provided.
			if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {}});
				});
				return false;
			}
		}
		let requestUrl = aemApiUrl;
		switch (additionalProductsService) {
			case this.VehicleServices.ASS:
				break;
			case this.VehicleServices.RCOS:
				requestUrl += SERVLET_ENDPOINT_RCOS_ADDITIONAL_PRODUCTS
				if (VWFSUtils.getURLParameter("ncbp_dis_config")) {
					let ncbp_dis_config = VWFSUtils.getURLParameter("ncbp_dis_config");
					requestUrl = VWFSUtils.addUrlParam(requestUrl, "ncbp_dis_config", ncbp_dis_config);
				}
				break;
			default:
				console.debug("Error: AemDataServiceMediator.js: Wrong Additional Products Service.");
				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'additionalProductsFailed', {detail: {}});
				});
				return false;
		}

		if (carUuid) {
			requestUrl = VWFSUtils.addUrlParam(requestUrl, "car_uuid", carUuid);
		} else {
			console.debug("Error: AemDataServiceMediator.js: Missing Vehicle Id.");
			// FIRE EVENT
			document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
				VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {}});
			});
			return false;
		}

		// PERFORM REQUEST
        var request = new XMLHttpRequest();
        request.addEventListener("load", onAdditionalProductsLoaded);
        request.open("GET", requestUrl, true);
        request.send();
    };


    var onProductUuidLoaded = function (event) {
        if (event.target.readyState === XMLHttpRequest.DONE) {
            if (event.target.status === 200 && event.target.response) {
                let params = new URLSearchParams(window.location.search);
                params.set("car_uuid", event.target.response)
                window.location.search = params.toString()

			} else {

				console.debug("Error: AemDataServiceMediator.js: Request failed: " + event.target.status + " / " + event.target.responseText);

				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {}});
				});
			}

		}
	}


	/**
	 * ON VEHICLE DETAILS LOADED
	 */
	var onVehicleDetailsLoaded = function (event) {

		if (event.target.readyState === XMLHttpRequest.DONE) {
			if (event.target.status === 200) {

				// EXTRACT UUID
				var jsonObject = JSON.parse(event.target.response);
				var carUuid = jsonObject.carUUID;

				// WRITE DATA TO LOCAL STORAGE
				VWFSUtils.setItemToSessionStorage(carUuid, JSON.stringify(jsonObject))

				// FIRE EVENT
				document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'vehicleDataLoaded', {detail: {carUuid: carUuid}});
				});

			} else {

				console.debug("Error: AemDataServiceMediator.js: Request failed: " + event.target.status + " / " + event.target.responseText);

                // FIRE EVENT
                document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
                    VWFSUtils.dispatchEvent(component, 'vehicleDataFailed', {detail: {carUuid: carUuid}});
                });

            }
        }
    }

    var onAdditionalProductsLoaded = function (event) {
        if (event.target.readyState === XMLHttpRequest.DONE) {
            if (event.target.status === 200 && event.target.response) {

                // FIRE EVENT
                document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
                    VWFSUtils.dispatchEvent(component, 'additionalProductsLoaded', {detail: {additionalProducts: event.target.response}});
                });
            } else {

                console.debug("Error: AemDataServiceMediator.js: Request failed: " + event.target.status + " / " + event.target.responseText);
                // FIRE EVENT
                document.querySelectorAll("[data-aem-service-receiver]").forEach(function (component) {
					VWFSUtils.dispatchEvent(component, 'additionalProductsFailed', {detail: {}});
                });
            }

        }
    }

};
