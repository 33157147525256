window.VWFS = window.VWFS || {};
VWFS.CheckoutManager = function (checkoutButton) {
    console.debug("INFO CheckoutManager.js loaded");

    var checkoutTypes = {
        normal: 0,
        customerJourney: 2,
        productOfferPage: 3
    };

    var spinnerTypes = {
        disclaimer: 0,
        checkoutButton: 1
    };

    var spinner;
    var spinnerType;
    var errorModal = document.querySelector(".js-error-modal");
    var servletURL;
    var storefrontRequestHandler;
    var checkoutType;
    var carUuid;

    /**
     * OPEN LINK
     */
    this.openLink = function () {
        if (checkoutButton) {
            setSpinner();
            // GET URL FROM DATA-ATTRIBUTES
            servletURL = checkoutButton.getAttribute("data-checkout-path") + ".checkoutnonecache.json";
            storefrontRequestHandler = checkoutButton.hasAttribute("data-storefront-requesthandler-link");

            // In case of Storefront Request Handler, sfProductOption should be sent to checkout Servlet as Request Parameter
            if (storefrontRequestHandler || checkoutButton.classList.contains("js-checkout-button-customerJourney")) {
                handleCheckout();
            } else {
                // do get without anything
                checkoutType = checkoutTypes.normal;
            }
            // ADD CLICK-EVENT
            checkoutButton.addEventListener('click', onCheckoutButtonClick);
        }
    }

    /**
     * handle Checkout if Checkout Type Product offer Page or Customer Journey
     */
    var handleCheckout = function () {
        carUuid = VWFSUtils.getURLParameter("car_uuid");
        if (!carUuid && VWFSUtils.isInsideOf(checkoutButton, ".carsearch")) {
            var article = checkoutButton.closest("article");
            var carUuidAttribute = article.getAttribute("data-car-uuid");
            carUuid = carUuidAttribute != null ? carUuidAttribute : undefined;
        }

        if (!carUuid) {
            console.debug("Error: CheckoutManager.js: carUUID is Missing! it is required.");
            return false;
        } else {
            // ENABLE IF NOT BLOCKED BY PRODUCT OFFER PAGE
            if (!checkoutButton.hasAttribute("data-disabled")) {
                checkoutButton.removeAttribute("disabled");
            }
        }

        if (typeof ProductOfferMain === 'function') {
            checkoutType = checkoutTypes.productOfferPage;
        } else {
            checkoutType = checkoutTypes.customerJourney;
        }

    }

    /**
     * Set spinner and spinner type
     */
    var setSpinner = function () {
        if (checkoutButton) {
            // GET SPINNER
            if (checkoutButton.querySelector(".js-checkout-button-spinner")) {
                //use spinner on the checkout button
                spinner = checkoutButton.querySelector(".js-checkout-button-spinner");
                spinnerType = spinnerTypes.checkoutButton;
            } else {
                // else use the default
                spinner = document.querySelector("#id-modal-spinner");
                spinnerType = spinnerTypes.disclaimer;
            }
        }
    }
    /**
     * EVENT > ON CHECKOUT BUTTON CLICK
     */
    var onCheckoutButtonClick = function (event) {
        event.preventDefault();
        event.stopPropagation();

        // SHOW SPINNER AND DISABLE BUTTON
        hideSpinner(false);
        checkoutButton.setAttribute("disabled", true);

        if (checkoutType === checkoutTypes.customerJourney) {
            //extend with caruuid if carsearch or carpooldetails page

            //check if button is inside a generic calculator
            if (VWFSUtils.isInsideOf(checkoutButton, ".js-wrap-generic-calculator")) {

                //if yes, make a post request with checkout json
                sendCheckoutCustomerJourneyWithPostData();

            } else {

                //if not, make a get request without checkout json (so no validation is needed)
                sendCheckoutCustomerJourneySimple();

            }

        } else if (checkoutType === checkoutTypes.productOfferPage) {

            //set dataLayer for Analytics
            let currentProduct = JSON.parse(VWFSUtils.getItemFromSessionStorage(VWFSUtils.getURLParameter("car_uuid")));
            VWFSAnalyticsUtils.setCheckoutFrom(currentProduct.financialProduct.calculation.product);

            //if yes, make a post request with checkout json
            sendCheckoutCustomerJourneyWithPostData();

        } else {
            sendCheckoutButtonNormal();
        }
    }

    var sendCheckoutButtonNormal = function () {

        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", onStorefrontServiceLoad);
        xhr.open("GET", servletURL, true);
        xhr.send();

    }

    var sendCheckoutCustomerJourneySimple = function () {

        // ADD PARAMETER CAR_UUID TO URL
        var requestURL = VWFSUtils.addUrlParam(servletURL, "car_uuid", carUuid);

        // ADD PARAMETER REFERER TO URL
        requestURL = VWFSUtils.addUrlParam(requestURL, "from", window.location.protocol + "//" + window.location.host);

        // CRAETE AND SEND REQUEST
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", onStorefrontServiceLoad);
        xhr.open("GET", requestURL, true);
        xhr.send();

    }

    var sendCheckoutCustomerJourneyWithPostData = function () {

        //for a checkout button in CSbR
        var postData = getPostData();

        // ADD REFERER-PARAMETER
        var requestURL = VWFSUtils.addUrlParam(servletURL, "from", window.location.protocol + "//" + window.location.host);

        if (VWFSUtils.getURLParameter("ncbp_vehicle-service")) {
            requestURL = VWFSUtils.addUrlParam(requestURL, "vehicle-service", VWFSUtils.getURLParameter("ncbp_vehicle-service"))
        }

        if (VWFSUtils.getURLParameter("ncbp_rcos_product")) {
            requestURL = VWFSUtils.addUrlParam(requestURL, "rcos-product", VWFSUtils.getURLParameter("ncbp_rcos_product"))
        }

        if (postData) {

            var xhr = new XMLHttpRequest();
            xhr.addEventListener("load", onStorefrontServiceLoad);
            xhr.open("POST", requestURL, true);
            xhr.send(postData);

        } else {
            showErrorModal();
        }
    };


    var getPostData = function () {
        if (carUuid) {
            // GET CAR-DATA FROM LOCAL-STORAGE
            var carJSON = JSON.parse(VWFSUtils.getItemFromSessionStorage(carUuid));
            if (carJSON) {
                // REMOVE LINKS
                if (carJSON.links) {
                    delete carJSON.links;
                }
                // REMOVE CHECKOUT-LINKS
                if (carJSON.checkoutLinks) {
                    delete carJSON.checkoutLinks;
                }
                if (carJSON.vehicleData && carJSON.vehicleData.model) {

                    // REMOVE MARKETING DESCRIPTION
                    if (carJSON.vehicleData.model.descriptionMarketing || carJSON.vehicleData.model.descriptionMarketing === "") {
                        delete carJSON.vehicleData.model.descriptionMarketing;
                    }

                    // REMOVE CARD-TITLE
                    if (carJSON.vehicleData.model.cardTitle) {
                        delete carJSON.vehicleData.model.cardTitle;
                    }

                    // EXTEND DESCRIPTION FOR ES-CHECKOUT
                    if (!carJSON.vehicleData.model.description && carJSON.vehicleData.model.descriptionLong) {

                        // ASSIGN 'descriptionLong' TO 'description' BUT WITHOUT HTML-TAGS
                        carJSON.vehicleData.model.description = VWFSUtils.stripHtml(carJSON.vehicleData.model.descriptionLong);
                    }

                    //remove all HTML Tags from description and descriptionLong
                    if (carJSON.vehicleData.model.description) {
                        carJSON.vehicleData.model.description = VWFSUtils.stripHtml(carJSON.vehicleData.model.description);
                    }
                    if (carJSON.vehicleData.model.descriptionLong) {
                        carJSON.vehicleData.model.descriptionLong = VWFSUtils.stripHtml(carJSON.vehicleData.model.descriptionLong);
                    }
                }
                // REMOVE SIMILAR CARS
                if (carJSON.similarCars && carJSON.similarCars.length > 0) {
                    delete carJSON.similarCars;
                }

                updateCarJSONWithUrlParameters(carJSON)
                updateCarJSONWithExternalIdentifier(carJSON)

                // SET POST DATA
                return JSON.stringify(carJSON);
            }
        }
    }

    /**
     * EVENT > ON STOREFRONT SERVICE LOAD
     */
    var onStorefrontServiceLoad = function (event) {

        var response = getResponse(event);
        if (response) {
            // HIDE SPINNER
            hideSpinner(true);

            var url = getURL(response);

            if (VWFSUtils.isIOS()) {
                console.debug("INFO: run on iOS");
                checkoutButton.setAttribute("target", "_self");
            }
            // ADD SATELLITE PARAMETER - de_apid (some kind of affiliate id)
            if (typeof _satellite !== "undefined" && _satellite) {
                let deApid = _satellite.getVar('de_apid');
                if (deApid) {
                    url = VWFSUtils.addUrlParam(url, "apid", deApid);
                }
            }
            // OPEN URL
            openURL(url);
        }
    }

    /**
     * ERROR HANDLING
     */
    var showErrorModal = function () {
        if (checkoutType === checkoutTypes.productOfferPage) {
            VWFSUtils.dispatchEvent(document.querySelector(".o-main"), 'checkoutFailed');
            checkoutButton.removeAttribute("disabled");
            hideSpinner(true);
        } else {
            hideSpinner(true);
            checkoutButton.removeAttribute("disabled");
            errorModal.setAttribute('aria-hidden', 'false');
        }
    }

    var hideSpinner = function (hide) {
        if (spinnerType === spinnerTypes.disclaimer) {
            spinner.setAttribute('aria-hidden', hide);
            spinner.querySelector('.c-spinner--full-page').setAttribute('aria-busy', !hide.toString());
        } else {
            spinner.hidden = hide;
        }
    }

    var updateCarJSONWithExternalIdentifier = function (carJSON) {
        if (window.mercuryUser) {
            carJSON["externalIdentifier"] = {
                "id": mercuryUser.userId,
                "type": "MERCURYCHATID"
            }
        }

        // add external id from local storage (traci_s parameter) to carJSON
        const externalId = VWFSUtils.getCookie("externalIdentifier");
        if (externalId) {
            carJSON["customerData"] = {externalCustomerIdentifiers: [{id: externalId, type: "CAMPAIGNID"}]}
        } else if (carJSON.customerData && carJSON.customerData.externalCustomerIdentifiers) {
            // remove external id if it is neither set nor recent
            delete carJSON.customerData.externalCustomerIdentifiers;
        }
    }

    var updateCarJSONWithUrlParameters = function (carJSON) {
        if (VWFSUtils.getURLParameter('ncbp_corporate_customer_id')) {
            carJSON['corporateCustomerId'] = VWFSUtils.getURLParameter('ncbp_corporate_customer_id');
        }

        if (VWFSUtils.getURLParameter('ncbp_dis_config')) {
            carJSON['dis_config'] = VWFSUtils.getURLParameter('ncbp_dis_config');
        }

        if (VWFSUtils.getURLParameter('ncbp_storefront-transaction-id')) {
            if (!carJSON['transaction']) {
                carJSON['transaction'] = {}
            }
            carJSON.transaction.storefrontTransactionId = VWFSUtils.getURLParameter('ncbp_storefront-transaction-id');
        }
    }

    var getResponse = function (event) {
        if (event.target.readyState === XMLHttpRequest.DONE) {
            if (event.target && event.target.status === 200 && event.target.response && JSON.parse(event.target.response) != null) {
                let response = JSON.parse(event.target.response);
                if (response && !response.errors) {
                    return response;
                } else {
                    console.debug("Error: CheckoutManager.js: Response is missing or contains errors.");
                    showErrorModal();
                }
            } else {
                console.debug("Error: CheckoutManager.js: Request failed: " + event.target.status + " / " + event.target.responseText);
                showErrorModal();
            }
        }
        return null;
    }

    var getURL = function (response) {
        var url;
        if (VWFSUtils.getObjectPathExists(response, "data.checkout.salesChannel.entryPointUrl") && response.data.checkout.salesChannel.entryPointUrl.length > 0) {
            // 1. check data.checkout.salesChannel.entryPointUrl
            url = response.data.checkout.salesChannel.entryPointUrl;
        } else if (VWFSUtils.getObjectPathExists(response, "salesChannel.entryPointUrl") && response.salesChannel.entryPointUrl.length > 0) {
            // 2. check salesChannel.entryPointUrl
            url = response.salesChannel.entryPointUrl;
        } else if (VWFSUtils.getObjectPathExists(response, "data.checkout.url") && response.data.checkout.url.length > 0) {
            // 3. check data.checkout.url for Market CZ
            url = response.data.checkout.url;
        } else {
            // as default use the normal href as url
            url = checkoutButton.getAttribute("data-href");
        }
        if (url && url.includes("storefrontID")) {
            let id;
            if(response.data && response.data.checkout && response.data.checkout.id) {
                id = response.data.checkout.id;
            }
            if (id === undefined && response.id) {
                id = response.id;
            } else {
                // if there is no id, show Error Modal
                console.debug("Error: CheckoutManager.js: ID is missing or it's empty.");
                showErrorModal();
                return;
            }
            url = url.replace("storefrontID", id);
        }
        return url;
    }

    var openURL = function (url) {
        if (url && url.length > 0) {
            // handling in case Button has disclaimer
            if (checkoutButton.getAttribute("data-disclaimer-ref") != null) {
                new VWFS.DisclaimerManager(checkoutButton).openDisclaimer(url);
            } else if (checkoutButton.hasAttribute("target") && checkoutButton.getAttribute("target") === "_blank") {
                window.open(url, '_blank');
            } else {
                window.location.href = url;
            }
        } else {
            console.debug("Error: CheckoutManager.js: URL is missing or it's empty.");
            showErrorModal();
        }
    }
};
